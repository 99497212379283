<template>
  <PullRefresh v-model="refresh" @refresh="onRefresh" pulling-text="Pull down to refresh..."
    loosing-text="Release to refresh..." loading-text="loading..." success-text="Loading completed" class="wrap">
    <div class="top_bar">
      <div class="userinfo_bar">
        <div class="left">
          <keep-alive>
            <VanImage width="132" height="32" class="logo_img" :src="require('@/assets/Img/login/page_logo.png')">
            </VanImage>
          </keep-alive>
        </div>
        <div class="right_bar">
          <!-- 站内信 -->
          <template>
            <Badge @click.stop="$router.push('/Message')" class="right" :color="verConfig.unreadBg || '#00adff'"
              v-if="!!unreadNum">
              <div class="text" slot="content" :style="{ color: verConfig.unreadColor || '' }">
                {{ unreadNum }}
              </div>
              <i class="iconfont icon-gl-envelope2" style="color: var(--theme);"
                @click.stop="$router.push('/Message')"></i>
            </Badge>
            <div class="right" v-else>
              <i class="iconfont icon-gl-envelope2" style="color: var(--theme);" @click="$router.push('/Message')"></i>
            </div>
          </template>
          <div class="balance" v-if="verConfig.homeShowBalanceAtTop">
            {{ $money(userInfo.money || 0) }}
          </div>
        </div>
      </div>
      <keep-alive>
        <div class="banner_bar">
          <Banner />
        </div>
      </keep-alive>
      <keep-alive>
        <div class="notice_bar">
          <Notice />
        </div>
      </keep-alive>
    </div>
    <div class="swiper-new">
      <div class="swiper-new-top" :class="{ flex_reverse: lan === 'ar' }">
        <div class="swiper-new-top-left" :class="{ flex_reverse: lan === 'ar' }">
          <img class="user_img" src="../../assets/Img/myImg/MyOne.png" alt="" v-if="!userInfo.userimg" />
          <img v-else :src="getImageUrl(userInfo.userimg)" class="user_img" alt="" />
          <span>{{ userInfo.username || $t("newAdd.noLoginUserName") }}
            <br />
            {{ $t("mine.invitationCode") }}: &nbsp;{{ userInfo.id || "" }}
          </span>
        </div>
        <div class="swiper-new-top-right">
          <template v-if="!verConfig.vipHide">
            <div class="vip" :style="lan === 'ar' && { marginLeft: 0, marginRight: 'calc(50rem / 16)' }"
              v-if="verConfig.levelType === 'recharge'">
              {{ userInfo.rank_title }}
            </div>
            <div class="vip" v-else>LEVEL{{ userInfo.level + 1 || 1 }}</div>
          </template>
        </div>
      </div>
      <div class="swiper-balance-com">
        <span>{{ $t("Home.WithdrawSu.Balance") }}</span>
        <p>{{ userInfo.money || '0.00' }}</p>
      </div>
      <div class="swiper-gift-com" :style="lan == 'ar' ? { fontSize: 'calc(16rem / 16)' } : {}">
        <div class="gift-yesterday">
          <p>
            <span class="gift-yesterday-tag"></span>
            {{ $t("add.Yesterday") }}
          </p>
          <div>
            <p>{{ userInfo.yesterday_gift || "0.00" }}</p>
          </div>
        </div>
        <div class="gift-today">
          <p>
            <span class="gift-today-tag"></span>
            {{ $t("add.Today") }}
          </p>
          <div>
            <p>
              {{ userInfo.today_gift || "0.00" }}
            </p>
          </div>
        </div>
        <div class="gift-expected-earnings">
          <p>
            <span class="gift-expected-earnings-tag"></span>
            {{ $t("expectedEarningsTitle") }}
          </p>
          <div>
            <p>
              {{ `${showGiftExpectedEarnings(7)} - ${showGiftExpectedEarnings(9)} ` }}
            </p>
          </div>
        </div>
      </div>
      <div class="swiper-balance-com" :class="{ flex_reverse: lan === 'ar' }">
        <div class="left">
          <div>{{ $t("mine.totalBalance") }}</div>
          <div class="value">
            {{ `${$money(totalBalance, false)}USDT ~ ${rateBalance} ${unitTitle}` }}
            <SelectCurrency v-model="unit" style="width: fit-content" @onConfirm="unitChange">
              <img src="../../assets/mine/edit.png" alt="" />
            </SelectCurrency>
          </div>
        </div>
      </div>
    </div>
    <keep-alive>
      <div class="trade_bar">
        <div class="recharge" v-for="( item, index ) in  company " :key="index" @click="handleTo(item)"
          :style="{ backgroundImage: `url(${item.img})` }">
          <div class="text">{{ item.title }}&gt;&gt;</div>
        </div>
      </div>
    </keep-alive>
    <!-- verConfig.homeShowLevels -->
    <div class="levels_bar">
      <div class="level_item" v-for="( item, index ) in  levelsData " :key="index"
        :style="{ background: levelItemBG(item, index) }">
        <img src="../../assets/images/levels/current.png" class="current" v-if="userInfo.rank_title === item.title" />
        <div class="level_titile">{{ item.title || "" }}</div>
        <div class="commission">
          <div class="text" :style="lan == 'ar' ? { fontSize: 'calc(16rem / 16)' } : {}">{{ "newAdd.commission" | lan }}
          </div>
          <div class="num">{{ item.order_num }}</div>
        </div>
        <div class="order_num">
          <div class="text" :style="lan == 'ar' ? { fontSize: 'calc(16rem / 16)' } : {}">{{ "level.order_num" | lan }}
          </div>
          <div class="num">{{ getCommissionRate(item) }}</div>
        </div>
      </div>
    </div>
    <div class="hall_bar">
      <div class="title_bar">
        <div class="left">
          <div class="triangle1"></div>
          <div class="triangle2"></div>
          <div class="triangle3"></div>
        </div>
        <div class="title">{{ $t("Home.HallofFame") }}</div>
        <div class="right">
          <div class="triangle1"></div>
          <div class="triangle2"></div>
          <div class="triangle3"></div>
        </div>
      </div>
      <HallMarquee class="marquee" :height="208">
        <template v-slot="{ item }">
          <div class="item">
            <div class="left">
              <VanImage width="40" height="40"
                :src="getImageUrl(`/uploads/system/userimg/userimg_${Math.round(Math.random() * 10)}.png`)" round>
                <VanImage slot="error" width="60" height="60" :src="require('@/assets/Img/myImg/MyOne.png')" round />
              </VanImage>
              <div class="info">
                <div class="nick_name">
                  {{ item.name }}
                </div>
                <div class="text">
                  {{ $t("successWithdrawof") }}
                </div>
              </div>
            </div>
            <div class="right">+{{ $money(item.money) }}</div>
          </div>
        </template>
      </HallMarquee>
    </div>
    <div class="partner_bar">
      <div class="title_bar">{{ $t("home.title.patener") }}</div>
      <div class="imgs_wrap">
        <!-- <img v-for="( item, index ) in  imgs_wrap " :key=" index "
          :src=" require('@/assets/home/patener/' + item.img + '_' + theme + '.png') " alt="" /> -->
        <img v-for="( item, index ) in 12 " :key="index" :src="require('@/assets/home/patener/' + (index + 1) + '.png')"
          alt="" />
      </div>
    </div>
    <div class="wrap_old">
      <div class="wrapper" @click.stop="contentShow = false" v-if="contentShow && !!noticeContent">
        <div class="content_wrapper" @click="() => { }">
          <div class="title">{{ $t("home.title.notice") }}</div>
          <div class="content" v-html="noticeContent"></div>
          <div class="ok" @click="contentShow = false">
            {{ $t("Button.confirm") }}
          </div>
        </div>
      </div>
    </div>
  </PullRefresh>
</template>

<script>
import Tabbar from "@/components/Tabbar";
import LoadingRE from "@/components/LoadingRE";
import VueClipboard from "vue-clipboard2";
import { Toast } from "vant";
import Cookies from "js-cookie";
import { GET_CONFIG_INFO } from "@/api";
import {
  Swipe,
  SwipeItem,
  Collapse,
  CollapseItem,
  Overlay,
  PullRefresh,
  Icon,
  Image as VanImage,
  Badge,
} from "vant";
import dataDetail from "@/utils/dataDetail";
import { $get } from "../../utils/request";
import verConfig from "@/utils/verConfig";
import Vue from "vue";
import getRandNoticeData from "@/utils/randNoticeData";
import Br from "../../components/br.vue";
import Banner from "@/components/banner.vue";
import Notice from "@/components/homeNotice.vue";
import { mapGetters } from "vuex";
import HallMarquee from "@/components/hallMarquee.vue";
import SelectCurrency from "@/components/currencyPicker.vue";
export default {
  components: {
    Tabbar,
    LoadingRE,
    VueClipboard,
    Toast,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Overlay.name]: Overlay,
    PullRefresh,
    Br,
    Icon,
    Banner,
    VanImage,
    Badge,
    Notice,
    HallMarquee,
    [Icon.name]: Icon,
    SelectCurrency
  },
  data() {
    this.tools = [
      {
        // img: require("@/assets/home/wallet.png"),
        img: require("@/assets/home/new/wallet.png"),
        title: this.$t("home.btn.wallet"),
        func: () => {
          this.$router.push("Financial");
        },
      },
      {
        // img: require("@/assets/home/recharge.png"),
        img: require("@/assets/home/new/recharge.png"),
        title: this.$t("home.btn.recharge"),
        func: () => {
          // this.$router.push("new-recharge");
          this.toRecharge();
        },
      },
      {
        // img: require("@/assets/home/withdraw.png"),
        img: require("@/assets/home/new/withdraw.png"),
        title: this.$t("home.btn.withdraw"),
        func: () => {
          this.$router.push("new-withdraw");
        },
      },
      // {
      //   img: require("@/assets/home/invite.png"),
      //   title: this.$t("home.btn.invite"),
      //   func: () => {
      //     this.$router.push("inviteFriends");
      //   },
      // },
      {
        // img: require("@/assets/home/message.png"),
        img: require("@/assets/home/new/message.png"),
        title: this.$t("home.btn.message"),
        showMsg: true,
        func: () => {
          this.$router.push("/Message");
        },
      },
    ];
    this.company = [
      {
        icon: 'bell',
        title: this.$t("home.title.notice"),
        pagePath: "Notice",
        img: require('@/assets/home/notice.png'),
      },
      {
        icon: 'icon-gongsiguanli1',
        title: this.$t("mine.serviceTools.aboutUs"),
        pagePath: "AboutUs",
        img: require('@/assets/home/about.png')
      },
    ];
    this.company2 = [
      {
        // img: require("@/assets/home/P2P.png"),
        icon: 'icon-zhuanqian',
        title: this.$t("newTab.p2p"),
        pagePath: "p2p",
      },
      {
        // img: require("@/assets/home/order.png"),
        icon: 'icon-complete-fill',
        title: this.$t("newTab.order"),
        pagePath: "cart",
      },
    ]
    this.imgs_wrap = [
      { img: "walmart" },
      { img: "lazada" },
      { img: "tether" },
      { img: "binance" },
      { img: "paypal" },
      { img: "flipkart" },
      { img: "google" },
      { img: "phonePe" },
      { img: "sbi" },
      { img: "amazon" },
      { img: "paytm" },
      { img: "uberEats" },
      { img: "shopee" },
      { img: "ebay" },
      { img: "zomato" },
    ];
    this.levelsBGs = [
      ["#fafced", "#fcf7d4"],
      ["#fcf9ed", "#fef1c2"],
      ["#fcfaed", "#fceccb"],
      ["#fcf9ed", "#fce8cc"],
      ["#fcf7ed", "#fcdfcb"],
      ["#fcf3ed", "#fcd8cb"],
    ];
    return {
      welfareDialogContent: "",
      welfareDialogShow: false,
      refresh: false,
      unreadNum: 0,
      verConfig,
      islocalImg: true,
      // userInfo: {
      //   money: 0,
      //   frozen_price: 0,
      //   complete_total_price: 0,
      //   complete_yesterday_price: 0,
      //   frozen_rate_price: 0,
      // },
      country: Cookies.get("language"),
      bannerArr: [],
      noticeArr: [],
      inimenuArr: [
        {
          // title: 'Mission hall',
          title: this.$t("newAdd.missionHall"),
          img: require("../../assets/icon/1.png"),
          activeImg: require("../../assets/icon/1-active.png"),
          // value: 1,
        },
        {
          // title: 'Help center',
          title: this.$t("newAdd.helpCenter"),
          img: require("../../assets/icon/3.png"),
          activeImg: require("../../assets/icon/3-active.png"),
          // value: 2,
        },
        {
          // title: 'Invite friends',
          title: this.$t("newAdd.inviteFriends"),
          img: require("../../assets/icon/7.png"),
          activeImg: require("../../assets/icon/7-active.png"),
          // value: 3,
        },
      ],
      menuArr: [],
      noticeArrss: [],
      menuVal: 0,
      allowed: 0,
      contentShow: true,
      noticeContent: "",
      gradeArr: [
        {
          title: "",
          rate: "",
        },
        {
          title: "",
          rate: "",
        },
        {
          title: "",
          rate: "",
        },
        {
          title: "",
          rate: "",
        },
      ],
      videoArr: [
        {
          title: "video1",
        },
        {
          title: "video2",
        },
        {
          title: "video3",
        },
        {
          title: "video4",
        },
      ],
      helpData: [],
      activeNames: 0,
      service_url: "",
      loadingNum: 0,
      noticeShowIndex: 3,
      rechargePath: this.verConfig.pathNewRecharge
        ? "third-recharge-n"
        : "new-recharge",
      unit: ''
    };
  },
  computed: {
    ...mapGetters(["userInfo", "levels", "unitData", "theme"]),
    totalBalance() {
      const res = Number(this.userInfo.money) + Number(this.userInfo.point);
      return !!res ? res.toFixed(2) : "0.00";
    },
    rateBalance() {
      const res = (Number(this.userInfo.money) + Number(this.userInfo.point)) / this.unitRate;
      return !!res ? res.toFixed(2) : "0.00";
    },
    unitTitle() {
      if (!this.unit) {
        this.unit = this.userInfo.currency_code || ""
        return this.userInfo.currency_code || "";
      }
      const unitItem = this.unitData.find((item) => item.code === this.unit);
      this.unit = !!unitItem ? unitItem.title : "USD";
      return !!unitItem ? unitItem.title : "USD";
    },
    unitRate() {
      if (!this.unit) {
        const itemTemp = this.unitData.find(
          (item) => item.title === this.userInfo.currency_code
        );
        return !!itemTemp ? itemTemp.rate : 1;
      }
      const unitItem = this.unitData.find((item) => item.code === this.unit);
      return !!unitItem ? unitItem.rate : 1;
    },
    levelsData() {
      if (this.verConfig.levelsReverse) {
        return this.levels.reverse();
      }
      return this.levels;
    },
  },
  methods: {
    async unitChange(value, index) {
      this.unit = value.code;
      try {
        const {
          data: { ret, data, msg },
        } = await $post("/users/edit", { currency_code: value.code });
        if (ret == 1) {
          this.$store.dispatch("getUserInfo");
        }
      } catch (error) { }
    },
    getCommissionRate(level) {
      if (!level) return "0%";
      const { rate } = level;
      const rateArr = rate.split("-");
      if (rateArr.length === 1) return rate + "%";
      return `${Math.min.apply(null, rateArr)}%~${Math.max.apply(
        null,
        rateArr
      )}%`;
    },
    themeClick() {
      this.$store.dispatch('setTheme', this.theme == 'dark' ? 'light' : 'dark')
    },
    levelItemBG(item, index) {
      const i = index % this.levelsBGs.length;
      const bgItem = this.levelsBGs[i];
      return `linear-gradient(to right, ${bgItem[0]}, ${bgItem[1]})`;
    },
    handleTo(item) {
      this.$router.push(item.pagePath);
    },
    getImageUrl(url) {
      const reg = new RegExp("http");
      if (reg.test(url)) return url;
      return this.verConfig.url + url;
    },
    // add:点击充值的按钮
    RechargeEvent() {
      this.$router.push(this.rechargePath);
    },
    // add 分享
    goshare() {
      this.$router.push("inviteFriends");
    },
    // add:点击提现按钮
    WithdrawEvent() {
      this.$router.push({ name: "newWithdraw" });
    },
    // add:去下载
    downloadEvent() {
      if (localStorage.getItem("android")) {
        location.href = localStorage.getItem("android");
      } else {
        location.href = "http://www.baidu.com";
      }
    },
    // add/随机名字
    randName() {
      let name =
        `+${this.verConfig.areaCode}\n` +
        String(dataDetail.rand(20, 99)) +
        dataDetail.rand(3, 9) +
        "****" +
        parseInt(Math.random() * 10) +
        parseInt(Math.random() * 10) +
        parseInt(Math.random() * 10);
      return name;
    },
    // add/随机
    randMoney() {
      const { homeProfitIntervalMax, homeProfitInterval } = this.verConfig;
      let interval = homeProfitInterval || [100, 300000];

      if (homeProfitIntervalMax) {
        if (dataDetail.rand(0, 10) < 2) {
          interval = homeProfitIntervalMax;
        }
      }
      let probability = dataDetail.rand(0, 100);
      let res = 0;
      if (probability <= 30) {
        // if(this.verConfig.homeProfitFormat){
        //   return this.moneyFormat(dataDetail.rand(interval[0], interval[1] / 20))
        // }
        return dataDetail.rand(interval[0], interval[1] / 20);
      } else {
        // if(this.verConfig.homeProfitFormat){
        //   return this.moneyFormat(dataDetail.rand(interval[1] / 20, interval[1]))
        // }
        return dataDetail.rand(interval[1] / 20, interval[1]);
      }
    },
    moneyFormat(money) {
      const arr = money.toString().split("").reverse();
      for (let i = 0, len = arr.length; i < Math.floor(len / 3); i++) {
        arr.splice(3 * (i + 1) + i, 0, ".");
      }
      if (arr[arr.length - 1] === ".") {
        arr.pop();
      }
      return arr.reverse().join("");
    },
    // add:lunbo
    noticeIni() {
      for (let i = 0; i < 100; i++) {
        this.noticeArrss.push({
          name: this.randName(),
          money: this.randMoney(),
        });
      }
    },
    // add:lunbo
    noticeDataUpdate() {
      setInterval(() => {
        this.noticeArrss.splice(this.noticeShowIndex, 1, {
          name: this.randName(),
          money: this.randMoney(),
        });
        if (this.noticeShowIndex >= 4) {
          this.noticeShowIndex = 1;
        } else {
          this.noticeShowIndex++;
        }
      }, 3000);
    },
    // add
    changeIndex(index) {
      // if(index===4){
      //   this.$refs.swiper.swipeTo({
      //     index:0
      //   })
      // }
    },

    toInvite() {
      this.$router.push({ name: "InviteFriends" });
    },
    toRecharge() {
      if (this.ver == "amigo_vi") {
        this.$router.push("/RechargeDesc");
        return;
      }
      if (this.verConfig.pathNewRecharge)
        this.$router.push({ name: "thirdRechargeNew" });
      else this.$router.push({ name: "NewRecharge" });
    },
    toService() {
      // this.$api.toService();
    },
    toWithdraw() {
      this.$router.push({ name: "newWithdraw" });
    },
    toService() {
      // window.location.href = this.service_url
    },
    copySuccess() {
      // Toast('Copy success!')
      Toast(this.$t("newAdd.copySuccess"));
    },
    copyError() {
      // Toast('Copy the failure!')
      Toast(this.$t("newAdd.copyError"));
    },
    menuChange(val) {
      this.menuVal = val;
    },
    handleChange(val) { },
    // 获取等级数组
    getLevels() {
      this.$api.getLevel((res) => {
        this.loadingNum--;
        this.gradeArr = res;
      });
    },
    // 等级跳转
    toRushOrder(index) {
      this.$router.push({ name: "MakeMoney" });
      // this.$router.push({
      //   name: 'cart',
      //   params: {
      //     level: index,
      //   },
      // })
    },
    toUnlock() {
      this.$router.push({ name: "levelUpgrade" });
    },
    // getNotice(){
    //   this.$api.getNotice(res=>{
    //     this.noticeArr = (res.scroll_notice.split(","));
    //     this.service_url = res.service_url;
    //     this.$cookie.set('service_url',res.service_url);
    getNotice() {
      this.$api.getNotice((res) => {
        // this.noticeArr = res.scroll_notice.split(',')
        this.noticeArr.push(res.scroll_notice);
        this.service_url = res.service_url;
        localStorage.setItem("android", res.appurl_android);
        localStorage.setItem("service_url", res.service_url);
      });
    },
    getBanner() {
      this.loadingNum++;
      this.$api.getBanner(
        (res) => {
          this.loadingNum--;
          if (res.length) {
            this.bannerArr = res;
            this.islocalImg = false;
          }
        },
        (err) => {
          this.loadingNum--;
        }
      );
    },
    getConfigShare() {
      this.loadingNum++;
      GET_CONFIG_INFO({
        act: "share",
      })
        .then((r) => {
          this.loadingNum--;
          if (r.data.ret === 1) {
            this.inviteLink = r.data.data.text;
            this.$cookie.set("public-share-item", JSON.stringify(r.data.data));
          } else {
            this.inviteLink = "";
            this.$cookie.set("public-share-item", {});
          }
        })
        .catch((e) => {
          console.error(e);
          this.loadingNum--;
        });
    }, // 获取分享地址
    getMenuArr() {
      this.loadingNum++;
      this.$api.getHomeConfig(
        (res) => {
          this.loadingNum--;
          this.menuArr = res.lists;
        },
        (err) => {
          this.loadingNum--;
        }
      );
    },
    getHelpArr() {
      this.loadingNum++;
      this.$api.getHomeHelp(
        (res) => {
          this.loadingNum--;
          for (let i = 0; i < res.lists.length; i++) {
            let res_item = res.lists[i];
            res_item.content = res_item.content.replace(/<[^>]*>/gi, "");
          }
          this.helpData = res.lists;
        },
        (err) => {
          this.loadingNum--;
        }
      );
    },
    async getNoticeContent() {
      this.loadingNum++;
      try {
        const res = await $get("/content/winnotice");
        this.loadingNum--;
        this.noticeContent = res.data.data[0].content;
      } catch (error) {
        this.loadingNum--;
      }
    },
    async getMessage() {
      this.loadingNum++;
      try {
        const res = await $get("/users/message", {
          perPage: 999,
          status: "unread",
        });
        const { ret, msg, data } = res.data;
        this.loadingNum--;
        this.unreadNum = data.total || 0;
        if (!data.total) return;
        if (!!data.lists.length) {
          this.welfareDialogShow = true;
          this.welfareDialogContent = data.lists[0].content;
        }
        // console.log(data.total, "消息")
      } catch (error) {
        this.loadingNum--;
      }
    },
    showGiftExpectedEarnings(c) {
      const { today_gift, yesterday_gift } = this.userInfo;
      if (!(today_gift + yesterday_gift)) return "0.00";
      return ((today_gift + yesterday_gift) * c).toFixed(2);
    },
    onRefresh() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.getLevels();
      this.getNotice();
      if (!this.verConfig.hideNotice) {
        this.getNoticeContent();
      }
      this.getBanner();
      this.getConfigShare();
      this.getMenuArr();
      this.getHelpArr();
      this.$store.dispatch("getUserInfo");
    },
  },
  created() {
    Toast.loading({
      forbidClick: true,
      duration: 0,
    });
    this.getLevels();
    this.getNotice();
    if (!this.verConfig.hideNotice) {
      this.getNoticeContent();
    }
    this.getConfigShare();
    this.getMenuArr();
    this.getHelpArr();
    this.getMessage();
    this.$store.dispatch('getUserInfo')
  },
  mounted() {
    if (!this.$cookie.get("token")) {
      this.inimenuArr.splice(2, 1);
    }
    // this.noticeIni()
    this.noticeArrss = getRandNoticeData();
    // this.noticeDataUpdate();
  },
  destroyed() { },
  watch: {
    loadingNum: function (newVal) {
      if (!newVal) {
        setTimeout(() => {
          this.refresh = false;
          Toast.clear();
        }, 1000);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@Color: rgb(222, 140, 23);
@bg: var(--bg);
@sizeColor: var(--sizeColor);

// 弹性布局
.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

// 文字超出省略号
.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}

.spancolor {
  color: var(--color, @Color);
}

.wrap {
  min-height: 100vh;
  // background: #f6f8f9;
  background: var(--nav-background);

  .logo_img {
    /deep/ .van-image__img {
      width: calc(132rem / 16);
      height: calc(32rem / 16);
    }
  }

  .top_bar {
    // background: #0271dc;
    // background: var(--theme);
    background: var(--nav-background);
    padding: calc(13rem / 16);
    padding-bottom: calc(10rem / 16);

    .userinfo_bar {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;

        .info {
          margin-left: calc(7rem / 16);
          font-size: calc(16rem / 16);
          color: var(--theme);
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
      }

      .right_bar {
        display: flex;
        // flex-direction: column;
        // justify-content: space-around;
        // align-items: flex-end;
        align-items: center;

        .right {
          /deep/ .van-badge {
            border: none;
          }

          >.iconfont {
            color: #fff;
            font-size: calc(28rem / 16);
          }
        }

        img {
          width: calc(26rem / 16);
          height: calc(26rem / 16);
          margin-left: calc(10rem / 16);
        }

        .balance {
          color: #fff;
          margin-top: calc(10rem / 16);
        }
      }
    }

    .banner_bar {
      margin-top: calc(10rem / 16);
      border-radius: 10px;
      overflow: hidden;
    }

    .notice_bar {
      margin-top: calc(10rem / 16);
    }
  }

  .swiper-new {
    background: url(../../assets/Img/myImg/bg_01.6e175c41-new.png) no-repeat top;
    background-size: 100% 100%;
    padding: 1px;
    color: var(--sizeColor);
    // padding-bottom: calc(20rem / 16);
    // margin-top: calc(20rem / 16);

    >.swiper-new-top {
      display: flex;
      align-items: center;
      margin: calc(10rem / 16) 0 calc(16rem / 16);

      >div {
        // width: 50%;
        font-size: calc(16rem / 16);

        &.swiper-new-top-left {
          display: flex;
          align-items: center;
          position: relative;

          // &::after {
          //   content: " ";
          //   width: calc(2rem / 16);
          //   height: calc(40rem / 16);
          //   background: #fff;
          //   position: absolute;
          //   right: -10px;
          //   top: 50%;
          //   transform: translate(0, -50%);
          // }

          >.user_img {
            width: calc(50rem / 16);
            border-radius: calc(50rem / 16);
            margin: 0 calc(10rem / 16) 0 calc(20rem / 16);
          }
        }

        &.swiper-new-top-right {
          .vip {
            margin-left: calc(50rem / 16);
            margin-top: calc(18rem / 16);
          }
        }
      }


    }

    >.swiper-balance-com {
      padding: 0 calc(20rem / 16);
      margin-bottom: calc(16rem / 16);

      font-size: calc(18rem / 16);

      >span {
        font-weight: 600;
      }

      >p {
        font-weight: 600;
        margin-top: calc(8rem / 16);
      }
    }

    >.swiper-gift-com {
      padding: 0 calc(20rem / 16);
      font-size: calc(14rem / 16);
      display: flex;
      align-items: flex-start;

      >div {
        width: 33.33%;

        >p {
          display: flex;
          align-items: center;
          margin-bottom: calc(10rem / 16);

          >span {
            width: calc(10rem / 16);
            height: calc(10rem / 16);
            display: block;
            border-radius: calc(2rem / 16);
            margin-right: calc(5rem / 16);

            &.gift-yesterday-tag {
              background-color: #ae0a0a;
            }

            &.gift-today-tag {
              background-color: #6c04d6;
            }

            &.gift-expected-earnings-tag {
              background-color: #006936;
            }
          }
        }

        >div {
          >p {
            font-weight: 600;
          }
        }
      }
    }

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-top: calc(20rem / 16);
    }

    .value {
      font-size: calc(18rem / 16);
      font-weight: bold;
      margin-top: 6px;
      display: flex;
      align-items: center;

      img {
        width: calc(26rem / 16);
        height: calc(26rem / 16);
        margin-left: calc(8rem / 16);
      }
    }
  }

  .trade_bar {
    display: flex;
    margin: calc(13rem / 16);
    justify-content: space-between;
    @gap: calc(10rem / 16);
    @pad: calc(32rem / 16);

    >div {
      width: calc(100% / 2 - @gap);
      height: calc(60rem / 16);
      border-radius: 10px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      >.iconfont {
        font-size: calc(30rem / 16);
      }
    }

    .recharge {
      .text {
        font-size: calc(14rem / 16);
        padding: calc(4rem / 16) calc(16rem / 16);
        padding-left: calc(8rem / 16);
        background-color: var(--moduleColor);
        color: var(--btnsizeColor);
        line-height: calc(16rem / 16);
        border-radius: calc(14rem / 16) 0 0 calc(14rem / 16);
      }
    }

    .withdraw {
      color: var(--theme) // color: #04672d;
    }
  }

  .about_bar {
    .trade_bar();
    // justify-content: space-around;
    @gap: calc(15rem / 16);
    @pad: calc(32rem / 16);

    >div {
      border-radius: 6px;
      width: calc((100% - @gap) / 2);
      justify-content: flex-end;
      align-items: center;
      background-size: 100% 100%;
      padding: 0;

      >.title {
        font-size: calc(13rem / 16);
        padding: calc(6rem / 16) calc(8rem / 16);
        padding-left: calc(12rem / 16);
        // background-color: #0271dc;
        // background-color: var(--nav-background);
        color: var(--theme);
        // color: #fff;
        border-top-left-radius: 90px;
        border-bottom-left-radius: 90px;
      }
    }

    .item_bar {
      justify-content: center;
    }

    .about_item {
      background-color: @bg;
      color: var(--theme);
      display: flex;
      align-items: center;
      font-size: calc(14rem / 16);
      justify-content: center;
      height: calc(40rem / 16);
      font-weight: 500;
    }
  }

  .levels_bar {
    padding: calc(13rem / 16);
    border-radius: calc(8rem / 16);
    overflow: hidden;

    .level_item {
      position: relative;
      background: var(--theme);
      margin: calc(10rem / 16) 0;
      border-radius: calc(8rem / 16);
      height: calc(60rem / 16);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 calc(6rem / 16) 0 calc(10rem / 16);
      color: var(--levelColor);

      .level_titile {
        font-weight: 600;
      }

      .current {
        position: absolute;
        width: calc(30rem / 16);
        left: 0;
        top: 0;
      }

      >div {
        text-align: center;

        .text {
          font-size: calc(14rem / 16);
        }

        .num {
          font-size: calc(14rem / 16);
          margin-top: calc(6rem / 16);
          font-weight: 600;
        }
      }

      &:nth-child(1) {
        margin-top: 0;
      }

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

  .hall_bar {
    margin: calc(13rem / 16);
    // margin-top: 0;
    // background: #fff;
    background: @bg;
    border-radius: 10px;
    overflow: hidden;

    .left_triangle(@size: 10px) {
      border: @size solid transparent;
      border-right-width: calc(@size * 1.8);
      border-right-color: var(--moduleColor);
    }

    .right_triangle(@size: 10px) {
      border: @size solid transparent;
      border-left-width: calc(@size * 1.8);
      border-left-color: var(--moduleColor);
    }

    .title_bar {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: calc(8rem / 16);
      color: var(--moduleColor);

      .left {
        display: flex;
        align-items: center;

        .triangle1 {
          .left_triangle(calc(3rem / 16));
        }

        .triangle2 {
          .left_triangle(calc(4rem / 16));
        }

        .triangle3 {
          .left_triangle(calc(6rem / 16));
        }
      }

      .right {
        .left();

        .triangle1 {
          .right_triangle(calc(6rem / 16));
        }

        .triangle2 {
          .right_triangle(calc(4rem / 16));
        }

        .triangle3 {
          .right_triangle(calc(3rem / 16));
        }
      }

      .title {
        font-size: calc(20rem / 16);
        // font-family: PingFangSC-Medium;
      }
    }

    .hr {
      width: 90%;
      height: 1px;
      background-color: #0056a8;
      margin: 0 auto;
      margin-bottom: calc(5rem / 16);
    }

    marquee {
      .item {
        margin: calc(10rem / 16) calc(4rem / 16) 0;
        padding: calc(7rem / 16) calc(5rem / 16);
        padding-right: calc(14rem / 16);
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: calc(15rem / 16);
        // background-color: #f6f8f9;
        background-color: var(--marqueeColor);

        .left {
          display: flex;
          font-size: calc(14rem / 16);

          .info {
            margin-left: calc(4rem / 16);
            padding: 1px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .nick_name {
              // color: #5300b1;
              // color: var(--theme);
              // color: #666;
              color: var(--marqueeItemColor);
            }

            .text {
              letter-spacing: 0;
              color: var(--marqueeItemColor);
            }
          }
        }

        .right {
          color: var(--themes);
          font-size: calc(14rem / 16);
          letter-spacing: 0;
        }
      }
    }
  }

  .partner_bar {
    margin: 0 calc(13rem / 16);
    margin-bottom: calc(20rem / 16);

    .title_bar {
      margin-bottom: calc(8rem / 16);
      border-radius: calc(10rem / 16);
      // background: #fff;
      background: @bg;
      color: var(--moduleColor);
      line-height: calc(50rem / 16);
      text-align: center;
      // font-family: PingFangSC-Medium;
      font-size: calc(16rem / 16);
      font-weight: 600;
    }

    .imgs_wrap {
      display: flex;
      @gap: calc(12rem / 16);
      flex-wrap: wrap;

      >img {
        width: calc((100% - @gap * 2) / 3);
        border-radius: calc(10rem / 16);
        background-color: @bg;
        // height: calc(73rem / 16);
        margin-top: @gap;

        &:nth-child(1) {
          margin-top: 0;
        }

        &:nth-child(2) {
          margin-top: 0;
        }

        &:nth-child(3) {
          margin-top: 0;
        }

        &:nth-child(3n - 1) {
          margin-left: @gap;
          margin-right: @gap;
        }
      }
    }
  }

  .wrapper {
    // width: 100vw;
    padding: 0 calc(27rem / 16);
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 50px);
    position: fixed;
    left: 0;
    top: 0;
    width: calc(100vw - calc(54rem / 16));
    background: rgba(0, 0, 0, 0.8);

    .content_wrapper {
      // width: calc(100vw - calc(27rem / 16) * 2);
      width: 100%;
      max-height: 60vh;
      // height: calc(525rem / 16);
      background: #fff;
      padding: calc(11rem / 16) calc(18rem / 16);
      border-radius: calc(10rem / 16);
      position: relative;

      .content {
        max-height: calc(60vh - calc(100rem / 16));
        overflow: scroll;
      }

      .title {
        color: #333;
        line-height: calc(27rem / 16);
        font-weight: 600;
        font-size: calc(20rem / 16);
        text-align: center;
      }

      .ok {
        width: calc(138rem / 16);
        height: calc(46rem / 16);
        border-radius: calc(23rem / 16);
        background: #d597679e;
        color: #fff;
        margin: calc(11rem / 16) auto 0;
        text-align: center;
        font-size: calc(15rem / 16);
        line-height: calc(46rem / 16);
      }
    }
  }

  @grey: #f5f4f7;

  .download {
    background-color: #f5f4f7;
    padding: 5vw 0;

    .download_content {
      .flex(space-between);
      width: 85vw;
      margin: 0 auto;
      background-color: #fff;
      box-shadow: 0 0 2.667vw rgba(0, 0, 0, 0.05);
      border-radius: 2.667vw;
      padding: 3vw;

      img {
        width: 30px;
      }
    }
  }
}
</style>
