<template>
  <Swipe class="my-swipe" :autoplay="6000" indicator-color="white">
    <SwipeItem
      style="
        height: calc(160rem / 16);
        background-color: var(--bg)
      "
      v-if="islocalImg"
    >
    </SwipeItem>
    <template v-if="!islocalImg">
      <SwipeItem
        style="
          height: calc(150rem / 16);
          background-color: var(--bg);
        "
        v-for="(item, index) in bannerArr"
        :key="index"
      >
        <img :src="item.picture" class="imgs" alt="" />
      </SwipeItem>
    </template>
  </Swipe>
</template>

<script>
import { Swipe, SwipeItem } from "vant";
import { $get, dealError } from "@/utils";
export default {
  components: {
    Swipe,
    SwipeItem,
  },
  data() {
    return {
      bannerArr: [],
      islocalImg: true,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const { data } = await $get("/content/slide", { position: "home" });
        this.bannerArr = data || [];
        if (data.length > 0) {
          this.islocalImg = false;
        }
      } catch (error) {
        dealError(error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.my-swipe {
  .van-swipe-item {
    width: 100%;
    color: #fff;
    font-size: 20px;
    min-height: calc(150rem / 16);
    text-align: center;
    background-color: transparent;

    .imgs {
      width: 100%;
      height: 100%;
    }
  }
}
</style>