<template>
  <div class="currencyPickerWrap">
    <div @click.stop="show = true">
      <slot></slot>
    </div>
    <Popup v-model="show" position="bottom">
      <Picker :title="$t('selectCurrenycy')" show-toolbar :columns="unitData" @confirm="onConfirm" @cancel="onCancel"
        @change="onChange" :value-key="`code`" :default-index="defaultIndex" :confirm-button-text="$t('Button.confirm')"
        :cancel-button-text="$t('Button.cancle')">
      </Picker>
    </Popup>
  </div>
</template>

<script>
import { Popup, Picker } from "vant";
import { getLanguage } from "../i18n";
import { $get, $post } from "../utils/request";
import { mapGetters } from "vuex";
export default {
  components: { Popup, Picker },
  props: {
    value: {
      type: String | Number,
      default: "",
    },
    popTitle: {
      type: String,
      default: "",
    },
  },
  model: {
    prop: "value",
    event: "update",
  },
  data() {
    this.lanNow = getLanguage();
    return {
      show: false,
      columns: [],
      valueTemp: {},
    };
  },
  created() {
    // this.getColumns();
  },
  computed: {
    ...mapGetters(["unitData"]),
    defaultIndex() {
      const num = this.unitData.findIndex((item, index) => item.code == this.value)
      return num
    }
  },
  methods: {
    // async getColumns() {
    //   try {
    //     const {
    //       data: { ret, msg, data },
    //     } = await $get("/third/currency/rate");
    //     if (ret == 1) {
    //       this.columns = data;
    //     }
    //   } catch (error) {}
    // },
    onConfirm(value, index) {
      if (value.code === this.value) return this.onCancel();
      this.valueTemp = value;
      this.onCancel();
      this.$emit("update", value.code);
      this.$emit("onConfirm", value, index);
    },
    onChange() {
      this.$emit("onChange");
    },
    onCancel() {
      this.show = false;
    },
  },
};
</script>
<style lang="less" scoped>
@Color: var(--theme);
@background: var(--nav-background);
@bg: var(--bg);
@sizeColor: var(--sizeColor);

.currencyPickerWrap {
  width: 100%;
}

/deep/ .van-picker__toolbar {
  background-color: @bg;

  .van-picker__cancel {
    color: @sizeColor;
  }

  .van-picker__confirm {
    color: @Color;
  }
}
</style>